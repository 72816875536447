
import { ref, defineComponent, onMounted } from "vue"
import ApiService from "@/core/services/ApiService"
import { useRoute } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "validateReceipt",
  components: {},
  setup() {
    const validationState = ref(0)

    const route = useRoute()

    const validationReceiptData = ref({
      studentFullName: "",
      serviceNames: "",
      payingPersonFullName: "",
      paymentReceivingPersonFullName: "",
      amount: 0,
      totalPrice: 0,
      remainingPayment: 0,
      currencyUnit: "",
      transactionDate: undefined,
    })

    const GetValidation = async () => {
      try {
        let res = await ApiService.get("validate/" + route.params.id)

        let data = res.data
        validationReceiptData.value = data
        validationState.value = 1
      } catch (error) {
        console.log(error) // this is the main part. Use the response property from the error object
        validationState.value = -1
        return error
      }
    }

    onMounted(async () => {
      await GetValidation()
    })

    return {
      validationState,
      validationReceiptData,
      GetValidation,
      dateForm,
      DateFormatTypes,
      currencyForm,
    }
  },
})
